import { useState, useEffect } from 'react';
import { collection, query, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';
import styled from 'styled-components';
import PageLayout from '../components/PageLayout';

const ScrollableFileList = styled.div`
  height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  
  -ms-overflow-style: none;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 20px 0;
  flex: 1;
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const FileItem = styled.div`
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: normal;
  align-self: center;
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeartIcon = styled.svg<{ $isLiked: boolean }>`
  width: 14px;
  height: 14px;
  fill: ${props => props.$isLiked ? 'white' : 'none'};
  stroke: white;
  stroke-width: 1px;
  transition: fill 0.2s ease;
  opacity: ${props => props.$isLiked ? 1 : 0.5};
`;

const HeartButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover ${HeartIcon} {
    opacity: 0.8;
  }
`;

const SearchInput = styled.input`
  color: white;
  background: none;
  border: none;
  text-transform: uppercase;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: normal;
  align-self: center;
  width: 200px;
  padding: 0;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #000;
  
  &::placeholder {
    color: white;
    opacity: 1;
  }
  
  &:focus {
    outline: none;
  }
`;

interface LookItem {
  id: string;
  title: string;
  url: string;
  likes: number;
  isLiked?: boolean;
}

const FileTitle = styled.a`
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
`;

const LookPage = () => {
  const [items, setItems] = useState<LookItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const q = query(collection(db, 'looks'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const lookItems: LookItem[] = [];
      const savedLikes = JSON.parse(localStorage.getItem('likedItems') || '[]');
      
      snapshot.forEach((doc) => {
        lookItems.push({ 
          id: doc.id, 
          ...doc.data(),
          isLiked: savedLikes.includes(doc.id)
        } as LookItem);
      });
      setItems(lookItems);
    });

    return () => unsubscribe();
  }, []);

  const handleLike = async (itemId: string) => {
    const itemRef = doc(db, 'looks', itemId);
    const item = items.find(i => i.id === itemId);
    
    if (item) {
      const savedLikes = JSON.parse(localStorage.getItem('likedItems') || '[]');
      const isCurrentlyLiked = savedLikes.includes(itemId);
      
      if (!isCurrentlyLiked) {
        // Add to liked items
        savedLikes.push(itemId);
        await updateDoc(itemRef, {
          likes: (item.likes || 0) + 1
        });
      } else {
        // Remove from liked items
        const index = savedLikes.indexOf(itemId);
        savedLikes.splice(index, 1);
        await updateDoc(itemRef, {
          likes: Math.max((item.likes || 0) - 1, 0)
        });
      }
      
      localStorage.setItem('likedItems', JSON.stringify(savedLikes));
      
      setItems(prevItems => 
        prevItems.map(i => 
          i.id === itemId ? { ...i, isLiked: !isCurrentlyLiked } : i
        )
      );
    }
  };

  const filteredItems = items.filter(item => 
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <PageLayout backTo="/">
      <SearchInput
        type="text"
        placeholder="SEARCH"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <ScrollableFileList>
        <FileList>
          {filteredItems.map((item) => (
            <FileItem key={item.id}>
              <FileTitle 
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.title}
              </FileTitle>
              <HeartButton onClick={() => handleLike(item.id)}>
                <HeartIcon 
                  $isLiked={item.isLiked || false}
                  viewBox="0 0 24 24"
                >
                  <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                </HeartIcon>
              </HeartButton>
            </FileItem>
          ))}
        </FileList>
      </ScrollableFileList>
    </PageLayout>
  );
};

export default LookPage;
