import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    background: #000;
    height: 100%;
    overflow: hidden;
  }

  body {
    color: #fff;
    font-family: "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 0 40px;
    position: relative;
  }

  .page-navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    position: relative;
  }

  .back-button {
    position: absolute;
    bottom: 30vh;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    width: 19px;
    height: 19px;
  }

  @media screen and (max-width: 428px) {
    .page-container {
      height: 100vh;
      overflow-y: auto;
    }
    
    .back-button {
      position: absolute;
      bottom: 29vh;
    }
  }
`;

export default GlobalStyles;
