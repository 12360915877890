import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PageLayout from '../components/PageLayout';

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const MenuItem = styled.button`
  color: white;
  background: none;
  border: none;
  text-transform: uppercase;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: normal;
  align-self: center;
  width: 120px;
  cursor: pointer;
  padding: 0;
  
  &:hover {
    opacity: 0.8;
  }
`;

const ContactText = styled.p`
  color: white;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: normal;
  text-align: left;
  align-self: center;
  width: 120px;
  margin: 0;
  padding: 0;
`;

const SettingsPage = () => {
  const navigate = useNavigate();

  return (
    <PageLayout backTo="/">
      <MenuList>
        <MenuItem onClick={() => navigate('/privacy')}>PRIVACY</MenuItem>
        <ContactText>CONTACT@BYLAIS.COM</ContactText>
      </MenuList>
    </PageLayout>
  );
};

export default SettingsPage;
