import styled from 'styled-components';
import PageLayout from '../components/PageLayout';

const PlatformLink = styled.a`
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: normal;
  align-self: center;
  width: 120px;
  
  &:hover {
    opacity: 0.8;
  }
`;

const StreamPage = () => {
  const platforms = [
    { name: 'SPOTIFY', url: 'https://open.spotify.com/artist/3BvDOrpqzFP2x8KxsYTbuI' },
    { name: 'APPLE MUSIC', url: 'https://music.apple.com/us/artist/lais/1392486216' },
    { name: 'AMAZON MUSIC', url: 'https://music.amazon.com.au/artists/B000THATJ6/lais' },
    { name: 'SOUNDCLOUD', url: 'https://soundcloud.com/lais' },
    { name: 'DEEZER', url: 'https://www.deezer.com/en/artist/76503642' }
  ];

  return (
    <PageLayout backTo="/">
      {platforms.map((platform) => (
        <PlatformLink 
          key={platform.name}
          href={platform.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {platform.name}
        </PlatformLink>
      ))}
    </PageLayout>
  );
};

export default StreamPage;
