import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import styled from 'styled-components';
import arrow from '../assets/arrow.svg';
import BackButton from '../components/BackButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #000;
  position: relative;
  padding: 0 40px;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  position: relative;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 100px;
`;

const EmailInput = styled.input`
  color: white;
  background: none;
  border: none;
  text-transform: uppercase;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: normal;
  align-self: center;
  width: 200px;
  padding: 0;
  
  &::placeholder {
    color: white;
    opacity: 1;
  }
  
  &:focus {
    outline: none;
  }
`;

interface SubmitButtonProps {
  $visible: boolean;
}

const SubmitButton = styled.button<SubmitButtonProps>`
  color: white;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-top: 20px;
  align-self: flex-start;
  margin-left: calc(50% - 100px);
  display: ${props => props.$visible ? 'block' : 'none'};
  
  &:hover {
    opacity: 0.8;
  }
`;

const BrandText = styled.div`
  color: white;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: left;
  font-weight: normal;
  align-self: center;
  width: 200px;
`;

const SuccessMessage = styled.p`
  color: white;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 60px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 30vh;
  left: 50%;
  transform: translateX(-50%);
  width: 19px;
  height: 19px;

    @media screen and (max-width: 428px) {
    position: absolute;  // Changed from fixed to absolute
    bottom: 29vh;     // Keep consistent with desktop
  }
`;

const ShopPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = async () => {
    if (!email.trim() || !email.includes('@')) {
      return;
    }

    try {
      await addDoc(collection(db, 'emails'), {
        email: email.trim(),
        timestamp: new Date()
      });
      setEmail('');
      setShowSuccess(true);
    } catch (error) {
      console.error('Error saving email:', error);
    }
  };

  return (
    <Container>
      <Navigation>
        <ContentWrapper>
          <BrandText>BY LAIS</BrandText>
          <EmailInput
            type="email"
            value={showSuccess ? '' : email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={showSuccess ? "REQUEST RECEIVED" : "ENTER EMAIL FOR UPDATES"}
            disabled={showSuccess}
          />
          <SubmitButton 
            onClick={handleSubmit}
            $visible={email.trim().length > 0 && !showSuccess}
          >
            SUBMIT
          </SubmitButton>
        </ContentWrapper>
        <ButtonWrapper>
          <BackButton onClick={() => navigate('/')} />
        </ButtonWrapper>
      </Navigation>
    </Container>
  );
};

export default ShopPage;
