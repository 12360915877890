import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import HomePage from './pages/HomePage';
import StreamPage from './pages/StreamPage';
import LookPage from './pages/LookPage';
import ShopPage from './pages/ShopPage';
import SettingsPage from './pages/SettingsPage';
import PrivacyPage from './pages/PrivacyPage';
import HelpPage from './pages/HelpPage';
import { AuthProvider } from './contexts/AuthContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import AdminPage from './pages/admin/AdminPage';
import AdminLogin from './pages/admin/AdminLogin';
import GlobalStyles from './styles/GlobalStyles';
import EmailsPage from './pages/admin/EmailsPage';

const MobileContainer = styled.div`
  max-width: 430px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background: #000;
  color: #fff;
  font-family: "Helvetica Neue", sans-serif;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

function App() {
  return (
    <AuthProvider>
      <GlobalStyles />
      <Router>
        <MobileContainer>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/stream" element={<StreamPage />} />
            <Route path="/vault" element={<LookPage />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route 
              path="/admin" 
              element={
                <ProtectedRoute>
                  <AdminPage />
                </ProtectedRoute>
              } 
            />
            <Route path="/admin/emails" element={
              <ProtectedRoute>
                <EmailsPage />
              </ProtectedRoute>
            } />
          </Routes>
        </MobileContainer>
      </Router>
    </AuthProvider>
  );
}

export default App;
