import styled from 'styled-components';
import BackButton from './BackButton';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #000;
  position: relative;
  padding: 0 40px;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  position: relative;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 100px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 30vh;
  left: 50%;
  transform: translateX(-50%);
  width: 19px;
  height: 19px;
  
  @media screen and (max-width: 428px) {
    position: absolute;
    bottom: 29vh;
  }
`;

interface PageLayoutProps {
  children: React.ReactNode;
  backTo?: string;
}

const PageLayout = ({ children, backTo = '/' }: PageLayoutProps) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Navigation>
        <ContentWrapper>
          {children}
        </ContentWrapper>
        <ButtonWrapper>
          <BackButton onClick={() => navigate(backTo)} />
        </ButtonWrapper>
      </Navigation>
    </Container>
  );
};

export default PageLayout;