import styled from 'styled-components';
import PageLayout from '../components/PageLayout';

const Content = styled.div`
  color: white;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  line-height: 1.6;
  text-align: left;
  font-weight: normal;
  align-self: center;
  width: 180px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 40px;
`;

const PrivacyPage = () => {
  return (
    <PageLayout backTo="/settings">
      <TextContainer>
        <Content>
          We respect your privacy and are committed to protecting your personal data.
        </Content>
        <Content>
          For any privacy-related questions, please contact us at contact@bylais.com
        </Content>
      </TextContainer>
    </PageLayout>
  );
};

export default PrivacyPage;
