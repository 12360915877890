import { useState, useEffect, useMemo } from 'react';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '../../config/firebase';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #000;
  position: relative;
  padding: 0 40px;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 300px;
  align-self: center;
  margin-bottom: 32px;
`;

const ExportButton = styled.button`
  color: white;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  
  &:hover {
    opacity: 0.8;
  }
`;

const ScrollableList = styled.div`
  height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  
  -ms-overflow-style: none;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const EmailList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const EmailItem = styled.div`
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  font-weight: normal;
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
`;

const SearchInput = styled.input`
  color: white;
  background: none;
  border: none;
  text-transform: uppercase;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: normal;
  width: 200px;
  padding: 0;
  
  &::placeholder {
    color: white;
    opacity: 1;
  }
  
  &:focus {
    outline: none;
  }
`;

const CountDisplay = styled.div`
  color: white;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  opacity: 0.5;
  margin-top: 32px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

interface EmailEntry {
  id: string;
  email: string;
  timestamp: any;
}

const EmailsPage = () => {
  const [emails, setEmails] = useState<EmailEntry[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const q = query(collection(db, 'emails'));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const emailList: EmailEntry[] = [];
      snapshot.forEach((doc) => {
        emailList.push({ id: doc.id, ...doc.data() } as EmailEntry);
      });
      setEmails(emailList);
    });

    return () => unsubscribe();
  }, []);

  const filteredEmails = useMemo(() => {
    return emails.filter(entry => 
      entry.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [emails, searchTerm]);

  const handleExport = () => {
    const csvContent = "data:text/csv;charset=utf-8," + 
      (searchTerm ? filteredEmails : emails).map(e => e.email).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "email_list.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <Navigation>
        <ContentWrapper>
          <FormContainer>
            <TopRow>
              <ExportButton onClick={handleExport}>EXPORT LIST</ExportButton>
              <CountDisplay>
                {filteredEmails.length}/{emails.length}
              </CountDisplay>
            </TopRow>
            <SearchInput
              type="text"
              placeholder="SEARCH EMAILS"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </FormContainer>
          <ScrollableList>
            <EmailList>
              {filteredEmails.map((entry) => (
                <EmailItem key={entry.id}>
                  {entry.email}
                </EmailItem>
              ))}
            </EmailList>
          </ScrollableList>
        </ContentWrapper>
      </Navigation>
    </Container>
  );
};

export default EmailsPage;
