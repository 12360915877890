import { useState, useEffect } from 'react';
import { db } from '../../config/firebase';
import { collection, addDoc, query, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import styled from 'styled-components';
import arrow from '../../assets/arrow.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #000;
  position: relative;
  padding: 0 40px;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const Input = styled.input`
  color: white;
  background: none;
  border: none;
  text-transform: uppercase;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: normal;
  align-self: center;
  width: 200px;
  padding: 0;
  margin-bottom: 20px;
  
  &::placeholder {
    color: white;
    opacity: 1;
  }
  
  &:focus {
    outline: none;
  }
`;

const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const FileItem = styled.div`
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  font-weight: normal;
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding-left: 0px;
`;

const AddButton = styled.button`
  color: white;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  white-space: nowrap;
  
  &:hover {
    opacity: 0.8;
  }
`;

const DeleteButton = styled.button`
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.5;
  padding: 0;
  margin-left: 10px;
  font-size: 14px;
  
  &:hover {
    opacity: 0.8;
  }
`;

const ItemTitle = styled.span`
  text-align: left;
  margin-left: 0;
`;

const ItemControls = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const HeartIcon = styled.svg<{ $isLiked: boolean }>`
  width: 14px;
  height: 14px;
  fill: ${props => props.$isLiked ? 'white' : 'none'};
  stroke: white;
  stroke-width: 1px;
  opacity: 0.5;
`;

const ScrollableFileList = styled.div`
  height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  
  -ms-overflow-style: none;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  width: 300px;
  align-self: center;
  margin-top: 60px;
`;

interface LookItem {
  id: string;
  title: string;
  url: string;
  likes: number;
}

const AdminPage = () => {
  const [items, setItems] = useState<LookItem[]>([]);
  const [newTitle, setNewTitle] = useState('');
  const [newUrl, setNewUrl] = useState('');

  useEffect(() => {
    console.log('Setting up Firestore listener...');
    const q = query(collection(db, 'looks'));
    
    const unsubscribe = onSnapshot(q, 
      (snapshot) => {
        console.log('Received Firestore update');
        const lookItems: LookItem[] = [];
        snapshot.forEach((doc) => {
          lookItems.push({ id: doc.id, ...doc.data() } as LookItem);
        });
        setItems(lookItems);
      },
      (error) => {
        console.error('Firestore listener error:', error);
      }
    );

    return () => {
      console.log('Cleaning up Firestore listener');
      unsubscribe();
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newTitle.trim() || !newUrl.trim()) {
      console.log('Empty fields detected');
      return;
    }

    try {
      console.log('Attempting to add document...');
      const docRef = await addDoc(collection(db, 'looks'), {
        title: newTitle.trim().toUpperCase(),
        url: newUrl.trim(),
        likes: 0,
        timestamp: new Date()
      });
      
      console.log('Document added with ID:', docRef.id);
      setNewTitle('');
      setNewUrl('');
    } catch (error) {
      console.error('Error adding document:', error);
      // Show the full error details
      if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
      }
    }
  };

  const handleDelete = async (itemId: string) => {
    try {
      await deleteDoc(doc(db, 'looks', itemId));
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  return (
    <Container>
      <Navigation>
        <ContentWrapper>
          <FormContainer>
            <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '32px' }}>
              <div>
                <Input
                  type="text"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  placeholder="ENTER TITLE"
                  required
                />
                <Input
                  type="text"
                  value={newUrl}
                  onChange={(e) => setNewUrl(e.target.value)}
                  placeholder="ENTER URL"
                  required
                />
              </div>
              <AddButton type="submit">ADD ITEM</AddButton>
            </form>
          </FormContainer>
          
          <ScrollableFileList>
            <FileList>
              {items.map((item) => (
                <FileItem key={item.id}>
                  <ItemTitle>{item.title}</ItemTitle>
                  <ItemControls>
                    <HeartIcon viewBox="0 0 24 24" $isLiked={false}>
                      <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                    </HeartIcon>
                    <span>{item.likes}</span>
                    <DeleteButton onClick={() => handleDelete(item.id)}>×</DeleteButton>
                  </ItemControls>
                </FileItem>
              ))}
            </FileList>
          </ScrollableFileList>
        </ContentWrapper>
      </Navigation>
    </Container>
  );
};

export default AdminPage;
