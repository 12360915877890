import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import styled from 'styled-components';
import arrow from '../../assets/arrow.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #000;
  position: relative;
  padding: 0 40px;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const Input = styled.input`
  color: white;
  background: none;
  border: none;
  text-transform: uppercase;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: normal;
  align-self: center;
  width: 200px;
  padding: 0;
  
  &::placeholder {
    color: white;
    opacity: 1;
  }
  
  &:focus {
    outline: none;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
`;

const LoginButton = styled.button`
  color: white;
  background: none;
  border: none;
  text-transform: uppercase;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  cursor: pointer;
  padding: 0;
  margin-top: 32px;
  align-self: flex-start;
  
  &:hover {
    opacity: 0.8;
  }
`;

const Error = styled.div`
  color: white;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 11px;
  letter-spacing: 0.5px;
  opacity: 0.5;
  margin-top: 16px;
`;

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const params = new URLSearchParams(location.search);
      const redirectTo = params.get('redirect') || '/admin';
      await login(email, password);
      navigate(redirectTo);
    } catch (err) {
      setError('Invalid login credentials');
    }
  };

  return (
    <Container>
      <Navigation>
        <ContentWrapper>
          <Form onSubmit={handleSubmit}>
            <Input
              type="email"
              placeholder="EMAIL"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              type="password"
              placeholder="PASSWORD"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ marginTop: '32px' }}
            />
            <LoginButton type="submit">LOGIN</LoginButton>
            {error && <Error>{error}</Error>}
          </Form>
        </ContentWrapper>
      </Navigation>
    </Container>
  );
};
export default AdminLogin;

