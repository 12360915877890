import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { db } from '../config/firebase';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import mainLogo from '../assets/1.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: #000;
  position: relative;
`;

const MainImage = styled.img`
  width: 180px;
  height: 180px;
  margin-top: 160px;
  margin-bottom: 80px;
`;

const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 48px;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
`;

const NavLink = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Helvetica Neue", sans-serif;
  letter-spacing: 0.5px;
  padding: 0;
  cursor: pointer;
  min-width: 60px;
  text-align: center;
  
  &:hover {
    opacity: 0.8;
  }
`;

const MenuButton = styled.button`
  margin-top: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
  letter-spacing: 0.5px;
`;

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <MainImage src={mainLogo} alt="Main" />
      <Navigation>
        <NavLinks>
          <NavLink onClick={() => navigate('/stream')}>STREAM</NavLink>
          <NavLink onClick={() => navigate('/vault')}>VAULT</NavLink>
          <NavLink onClick={() => navigate('/shop')}>SHOP</NavLink>
        </NavLinks>
        <MenuButton onClick={() => navigate('/settings')}>...</MenuButton>
      </Navigation>
    </Container>
  );
};

export default HomePage;
