import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyB65TNkNGd86qQ_bMWCfjdBeeEXs_Cu6HI",
    authDomain: "bylais-863f2.firebaseapp.com",
    projectId: "bylais-863f2",
    storageBucket: "bylais-863f2.firebasestorage.app",
    messagingSenderId: "567058724189",
    appId: "1:567058724189:web:fef22acf1797cfdea264b4",

 


};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const db = getFirestore(app);

// Enable logging in development
if (process.env.NODE_ENV === 'development') {
    console.log('Firebase initialized with config:', firebaseConfig);
}