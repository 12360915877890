import styled from 'styled-components';
import arrow from '../assets/arrow.svg';

const StyledButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;s
  
  img {
    width: 19px;
    height: 19px;
  }
`;

interface BackButtonProps {
  onClick: () => void;
}

const BackButton = ({ onClick }: BackButtonProps) => {
  return (
    <StyledButton onClick={onClick}>
      <img src={arrow} alt="Back" />
    </StyledButton>
  );
};

export default BackButton; 